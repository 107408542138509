import React from "react";
import ScrollAnimation from 'react-animate-on-scroll'
import { Button, Icon } from "react-materialize";
import { Text, Title } from "../../styles";
import constants from "../../static";

export const Idea = () => {
    return(
        <>
        <Title>Tem uma ideia e queria desenvolver com o Ifes?</Title>
        <Text style={{textAlign:'center'}}>Conheça nossos Termos de Parceria e entre em contato conosco!</Text>
        <ScrollAnimation animateOnce={true} animateIn='bounceInLeft'>
            <Button
                node="a"
                className="zoomEffect bg-text"
                large
                href={constants.sistema}
            >
                Seja nosso parceiro!
                <Icon right>
                arrow_drop_down
                </Icon>
            </Button>
        </ScrollAnimation>
        </>
    )
}