import React from "react";
import { Dropdown, Icon } from "react-materialize";
import { Link } from "react-router-dom";
import constants from "../../static";
import { Sidenav } from "../sidenav";
import { StyledNavbar } from "./styles";
const LogoImg = require("../../assets/logo.png");

export const PartnersNavbar = () => {
  return (
    <StyledNavbar
      alignLinks="right"
      brand={
        <Link to="/" className="noStyle">
          <img alt="Cabeçalho" src={LogoImg} height={85}></img>
        </Link>
      }
      menuIcon={<Icon>menu</Icon>}
      sidenav={<Sidenav />}
    >
      <Link to="/seja-nosso-parceiro">Seja nosso Parceiro</Link>

      <Link className="noStyle" to="contato">
        <Dropdown
          trigger={
            <Link to="/acao-de-pesquisa-e-extensao">
              Ações de Pesquisa e Extensão
              <Icon style={{ marginTop: "10px" }} right>
                arrow_drop_down
              </Icon>
            </Link>
          }
          options={{ hover: true }}
        >
          <Link to="/acoes-da-prppg">Ações de Pesquisa</Link>

          <Link to="/acoes-de-extensao">Ações de Extensão</Link>
        </Dropdown>
      </Link>

      <Link to="/contato">Contato</Link>

      <Link to="/manuais">Manuais</Link>

      <a href={constants.sistema}>Login</a>
    </StyledNavbar>
  );
};
