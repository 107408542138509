import React from "react";
import { FirstSection, SecondSection, SocialMedia } from "./styles";
const LogoImg = require('../../assets/logo.png');

export const Footer = () => {
    return(
        <footer>
            <FirstSection>
                <a style={{width:'15%'}} href="https://ifes.edu.br/">
                    <img alt="logo com link para site ifes" className="zoomEffect" style={{width:'100%'}} src={LogoImg}/>
                </a>
                <div>
                    <SocialMedia href="https://twitter.com/ifesoficial" className="fa fa-twitter zoomEffect"/>
                    <SocialMedia href="https://www.youtube.com/channel/UC2Ai9LJ_m_nitfmSAZ5--ZA" className="fa fa-youtube zoomEffect"/>
                    <SocialMedia href="https://www.facebook.com/ifesoficial/" className="fa fa-facebook zoomEffect"/>
                    <SocialMedia href="https://www.instagram.com/ifesoficial/" className="fa fa-instagram zoomEffect"/>
                    <SocialMedia href="https://www.linkedin.com/school/ifesoficial/" className="fa fa-linkedin zoomEffect"/>
                </div>
            </FirstSection>
            <SecondSection>Parcerias Ifes © 2022 IFES – Desenvolvido em React</SecondSection>
        </footer>
    )
}