import { CardPanel } from "react-materialize";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
        font-family: "Open Sans", Sans-serif;
    }

    span{
        filter: blur(0.2px);
    }

    h2{
        margin: 0;
        color: var(--e-global-color-text);
    }

    h1{
        margin: 0;
        color: var(--e-global-color-text);
    }

    a{
        font-family: "Roboto", Sans-serif;
        font-size: 13px;
        text-transform: none !important;
        font-weight: 500;
    }

    iframe{
        width: 100%;
    }

    a{
        color: var(--e-global-color-secondary);
        :focus-visible {
            outline: none;
        }
    }

    .row{
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .zoomEffect{
        transition: transform .2s;
    
        :hover{
            transform: scale(1.1);
        }
    }

    .content-box{
        box-sizing: content-box;
    }

    .bg-primary{
        background-color: var(--e-global-color-primary) !important;
        :hover{
            background-color: var(--e-global-color-primary);
        }
    }

    .bg-secondary{
        background-color: var(--e-global-color-secondary) !important;
        :hover{
            background-color: var(--e-global-color-secondary);
        }
    }

    .bg-text{
        background-color: var(--e-global-color-text) !important;
        :hover{
            background-color: var(--e-global-color-text);
        }
    }

    .bg-accent{
        background-color: var(--e-global-color-accent) !important;
        :hover{
            background-color: var(--e-global-color-accent);
        }
    }

    .bg-2{
        background-color: var(--e-global-color-2) !important;
        :hover{
            background-color: var(--e-global-color-2);
        }
    }

    .bg-3{
        background-color: var(--e-global-color-3) !important;
        :hover{
            background-color: var(--e-global-color-3);
        }
    }

    .bg-4{
        background-color: var(--e-global-color-4) !important;
        :hover{
            background-color: var(--e-global-color-4);
        }
    }

    .bg-6{
        background-color: var(--e-global-color-6) !important;
        :hover{
            background-color: var(--e-global-color-6);
        }
    }

    #sidenav_drop{
        width:100% !important;
         left:0px !important;
    }

    .sidenav-bg-link{
        color: var(--e-global-color-accent) !important;
    }

    :root{
        --e-global-color-primary: #009245;
        --e-global-color-secondary: #298C6A;
        --e-global-color-text: #284033;
        --e-global-color-accent: #24562A;
        --e-global-color-2: #24562A;
        --e-global-color-3: #0A2D11E8;
        --e-global-color-4: #B1D8CC;
        --e-global-color-5: #e70d47;
        --e-global-color-6: #4b00e7;
    }

    .mouseClick {
        cursor: pointer;
    }
`;

export const MyContainer = styled.div`
    max-width: 1045px;

    margin: 0px auto 0px auto;

    @media (max-width: 1065px) {
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const Title = styled.h2`
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const BigTitle = styled.h1`
    font-size: 40px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
`;

export const Text = styled.p`
    color: var( --e-global-color-text );
    font-size: 14px;
    line-height: 1.5;
    margin: 0 0 1em 0;
    text-align: justify;
`;

export const StyledCard = styled(CardPanel)`
    color: ${props => props.pink ? 'var(--e-global-color-5)' : 'var(--e-global-color-text)'};
    border: 1px solid ${props => props.pink ? 'black' : 'var(--e-global-color-text)'};
    box-shadow: 4px 4px 10px 0px ${props => props.pink ? 'black' : 'rgb(10 45 17 / 76%)'};
    border-radius: 5px;
    text-align: center;
    margin: 10px;
    max-width: 300px;

    i{
        color: ${props => props.pink ? 'black' : ''}
    }
    h2{
        color: ${props => props.pink ? 'var(--e-global-color-5)' : ''}
    }

    p{
        color: ${props => props.pink ? 'var(--e-global-color-5)' : ''}
    }
`;

export const StyledIcon = styled.i`
    width: 70px;
    height: 70px;
    font-size: 70px;
    margin-bottom: 10px;

    color: var(--e-global-color-text);
`;

export const FlexBox = styled.div`
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: ${props => props.center ? 'center' : 'space-around'};
`;