import React from "react";
import { Header } from "../../components/header";
import { MyContainer, StyledCard, Title, StyledIcon, BigTitle } from "../../styles";
import ScrollAnimation from 'react-animate-on-scroll';


export const Contact = () => {
    return(
        <>
        <Header page='contact'/>

        <MyContainer>
            <BigTitle>Entre em contato através de uma das opções abaixo:</BigTitle>
            <ScrollAnimation animateOnce={true} animateIn='bounceInLeft' animatePreScroll={false}>
                <StyledCard pink style={{margin:'auto'}}>
                    <StyledIcon className="fa fa-envelope"/>
                    <Title>parcerias@ifes.edu.br</Title>
                </StyledCard>
            </ScrollAnimation>
        </MyContainer>
        </>
    )
}