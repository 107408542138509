import React from "react";

export const Text = (props) => {
    const {page} = props;

    switch (page) {
        case 'contact':
            return(
                <>
                    Conheça as  <b>informações</b> referente ao email de 
                    contato do Parcerias Ifes, à Pró-reitora de Pesquisa 
                    e Pós-Graduação (PRPPG) e à Pró-reitoria de Extensão, 
                    contendo descrição das pró-reitorias e links para 
                    mais informações
                </>
                );
        case 'manuais':
            return(
                <>
                    Confira os <b>Manuais dos Usuários</b> para obter um entendimento completo das funcionalidades disponíveis na plataforma.
                </>
                );
        case 'extension':
            return(
                <>
                    As <b>Ações de extensão</b> são estruturadas pelas 
                    diretrizes: Impacto social;  Relação do Ifes com 
                    outros setores da sociedade;  Relação da ação de 
                    Extensão com Pesquisa e/ou Ensino;  Impacto na formação 
                    do estudante e Incentivo ao protagonismo estudantil
                </>
                );
        case 'partnership':
            return(
                <>
                    É possível estabelecer <b>parcerias</b> público e 
                    privado em conjunto com a Instituição Federal 
                    do Espírito Santo, sugerindo, apoiando e 
                    investindo em uma Ação de Extensão com o 
                    objetivo de promover o ensino, pesquisa, 
                    extensão e inovação.
                </>
                );
        case 'research':
            return(
                <>
                    Os <b>projetos de pesquisa</b> são direcionados pelo 
                    Ifes através da Diretoria de Pesquisa que coordena a 
                    elaboração de projetos institucionais referentes às 
                    atividades de pesquisa e supervisiona a definição das 
                    linhas de pesquisa do Instituto, além de fornecer 
                    orientação e apoio aos campis na execução dos regulamentos 
                    e normas, no âmbito das atividades de Pesquisa.
                </>
                );
        case 'researchExtension':
            return(
                <>
                    As <b>Ações de extensão</b> e <b>Ações de ensino</b> 
                    são estruturadas pelas diretrizes: Impacto social;  
                    Relação do Ifes com outros setores da sociedade;  
                    Relação da ação de Extensão com Pesquisa e/ou Ensino;  
                    Impacto na formação do estudante e Incentivo ao protagonismo estudantil
                </>
                );
        default:
            return(
            <>
                O <b>Portal de divulgação</b> das possibilidades 
                de atuação conjunta entre o Ifes e outras 
                organizações.
            </>
            );
    }

} 