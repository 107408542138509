import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-materialize";
import { MyContainer, Text, Title } from "../../styles";

export const MeetIfes = () => {
  return (
    <Row>
      <ScrollAnimation animateOnce={true} animateIn="fadeIn">
        <MyContainer>
          <Col s={12} m={12} l={6}>
            <Title>Conheça o IFES</Title>
            <Text>
              Com <b>25 campi</b> em funcionamento, incluindo o Centro de
              Referência em Formação e em Educação a Distância – Cefor, o Ifes
              se faz presente em todas as microrregiões capixabas. Possui ainda,
              49 polos de educação a distância, conta com um Polo de Inovação e
              a Cidade da Inovação. São ofertados desde cursos técnicos a
              mestrados e possui aproximadamente <b>36 mil alunos</b>. São 95
              cursos técnicos, 64 cursos de graduação, 34 especializações, 12
              mestrados e 1 doutorado profissional que se iniciou em 2020*.
            </Text>
            <Text>
              <i>*Dados de 2022</i>
            </Text>
          </Col>

          <Col s={12} m={12} l={6}>
            <iframe
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              //   src="https://maps.google.com/maps?q=Av.%20Rio%20Branco%2C%2050%2C%203%C2%BA%20andar%20%E2%80%93%20Santa%20L%C3%BAcia%20%E2%80%93%20Vit%C3%B3ria%20%E2%80%93%20ES%20%E2%80%93%20CEP%3A%2029056-264&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3742.391954227577!2d-40.2965886!3d-20.284037199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb8175e38929357%3A0x4205a79cffff6214!2sCidade%20da%20Inova%C3%A7%C3%A3o%20-%20Ifes!5e0!3m2!1spt-BR!2sbr!4v1729167089254!5m2!1spt-BR!2sbr"
              title="Av. Rio Branco, 50, 3º andar – Santa Lúcia – Vitória – ES – CEP: 29056-264"
              aria-label="Av. Rio Branco, 50, 3º andar – Santa Lúcia – Vitória – ES – CEP: 29056-264"
              data-origwidth=""
              data-origheight=""
              style={{ height: "300px" }}
            ></iframe>
          </Col>
        </MyContainer>
      </ScrollAnimation>
    </Row>
  );
};
