import React from "react";
import { Header } from "../../components/header";
import { BigTitle, MyContainer } from "../../styles";
import { Idea } from "../../components/idea";

export const Extension = () => {
    return (
        <>
        <Header page='extension'/>
        <BigTitle>
            Conheça as Ações de Extensão
        </BigTitle>
        <MyContainer style={{textAlign:'center', marginBottom:'30px'}}>
            <iframe title='extension' loading="lazy" scrolling="no" src="https://src.ifes.edu.br/src/public/consulta-acao.xhtml" width="100%" height="1000px" frameborder="0" data-origwidth="100%" data-origheight="1000px"></iframe>
            <Idea/>
        </MyContainer>
        </>
    )
}