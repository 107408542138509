import React from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import { Button, Col, Icon, Row } from "react-materialize";
import TypewriterComponent from "typewriter-effect";
import { Header } from "../../components/header";
import constants from "../../static";
import { MyContainer, Text, Title } from "../../styles";
import { BigText, CenterCounter, FlashyText, LinkList, StyledH2 } from "./styles";

export const Home = () => {
    return(
        <>
            <Header/>
            <Row>
                <MyContainer>
                    <Col s={12} m={12} l={7}>
                        <ScrollAnimation animateOnce={true} animateIn="bounceInLeft" animatePreScroll={false}>
                            <iframe width="574" height="323" src="https://www.youtube.com/embed/aANuDntBtOU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </ScrollAnimation>
                    </Col>

                    <Col>
                        <FlashyText>
                            <StyledH2>Quer ser uma organização</StyledH2>
                            <StyledH2>parceria em projetos de</StyledH2>
                            <TypewriterComponent
                                options={{
                                    strings: ['Desenvolvimento', 'Inovação', 'Pesquisa', 'Ensino', 'Extensão'],
                                    autoStart: true,
                                    loop: true,
                                }}/>
                            <StyledH2>junto com o Ifes?</StyledH2>
                            <ScrollAnimation animateOnce={true} animateIn='bounceInRight' animatePreScroll={false}>
                                <Button
                                    node="a"
                                    className="zoomEffect bg-text"
                                    small
                                    href={constants.sistema}
                                >
                                    Seja nosso parceiro!
                                    <Icon right>
                                    arrow_drop_down
                                    </Icon>
                                </Button>
                            </ScrollAnimation>
                        </FlashyText>
                    </Col>
                </MyContainer>
            </Row>

            <Row className="bg-4">
                <MyContainer>
                    <Col m={6} s={12}>
                        <ScrollAnimation animateOnce={true}  animateIn="fadeIn">
                            <Title>O que é a Plataforma de Parcerias Ifes?</Title>
                            <Text>
                            O Instituto Federal do Espírito Santo é a instituição 
                            referência de educação capixaba. Atua promovendo educação 
                            profissional pública de excelência, integrando ensino, 
                            pesquisa e extensão, para a construção de uma sociedade 
                            democrática, justa e sustentável. É organizado em 
                            estrutura de <i>multicampi</i> e tem a Reitoria como órgão 
                            executivo, que por sua vez, é composta pelo Gabinete do 
                            Reitor e pelas Pró-Reitorias.
                            <br/><br/>
                            Com o objetivo de ampliar o impacto dessas interações, o 
                            IFES desenvolveu a <b>Plataforma de Parcerias Ifes</b>, com objetivo de 
                            divulgar as Ações de Pesquisa e Extensão desenvolvidas em 
                            parcerias público e privada e promover interação entre 
                            possíveis parceiros.
                            </Text>
                        </ScrollAnimation>
                    </Col>
                    <Col m={6} s={12}>
                        <CenterCounter>
                            <BigText>
                                <CountUp end={314} duration={5}/>
                            </BigText>
                            <Title>
                                Parcerias
                            </Title>
                            <BigText>
                                <CountUp end={500} duration={5}/>
                            </BigText>
                            <Title>
                                Ações Vigentes
                            </Title>
                        </CenterCounter>
                    </Col>
                </MyContainer>
            </Row>

            <Row>
                <MyContainer>
                    <Col s={12} m={12} l={6}>
                        <ScrollAnimation animateOnce={true}  animateIn="bounceInLeft">
                            <iframe title="campis" src="https://www.google.com/maps/d/embed?mid=1CdJD2nXFwP0nAnDKHW3fBQXCel8&ehbc=2E312F" width="640" height="480"></iframe>
                        </ScrollAnimation>
                    </Col>

                    <Col s={12} m={12} l={6}>
                        <ScrollAnimation animateOnce={true}  animateIn="bounceInRight">
                            <Title>NOSSOS CAMPI</Title>
                            <Row>
                                <Col s={6}>
                                    <LinkList>
                                        <a href="https://alegre.ifes.edu.br/">Alegre</a>
                                        <a href="https://aracruz.ifes.edu.br/">Aracruz</a>
                                        <a href="https://saofrancisco.ifes.edu.br/">Barra de São Francisco</a>
                                        <a href="https://cachoeiro.ifes.edu.br/">Cachoeiro de Itapemirim</a>
                                        <a href="https://cariacica.ifes.edu.br/">Cariacica</a>
                                        <a href="https://cefor.ifes.edu.br/">Cefor</a>
                                        <a href="https://centroserrano.ifes.edu.br/">Centro-Serrano</a>
                                        <a href="https://colatina.ifes.edu.br/">Colatina</a>
                                        <a href="https://guarapari.ifes.edu.br/">Guarapari</a>
                                        <a href="https://ibatiba.ifes.edu.br/">Ibatiba</a>
                                        <a href="https://itapina.ifes.edu.br/">Itapina</a>
                                        <a href="https://linhares.ifes.edu.br/">Linhares</a>
                                    </LinkList>
                                </Col>

                                <Col s={6}>
                                    <LinkList>
                                        <a href="https://montanha.ifes.edu.br/">Montanha</a>
                                        <a href="https://novavenecia.ifes.edu.br/">Nova Venécia</a>
                                        <a href="https://piuma.ifes.edu.br/">Piúma</a>
                                        <a href="https://polo.ifes.edu.br/">Polo de Inovação</a>
                                        <a href="https://presidentekennedy.ifes.edu.br/">Presidente Kennedy</a>
                                        <a href="https://santateresa.ifes.edu.br/">Santa Teresa</a>
                                        <a href="http://saomateus.ifes.edu.br/">São Mateus</a>
                                        <a href="https://serra.ifes.edu.br/">Serra</a>
                                        <a href="https://vendanova.ifes.edu.br/">Venda Nova do Imigrante</a>
                                        <a href="https://viana.ifes.edu.br/">Viana</a>
                                        <a href="https://vilavelha.ifes.edu.br/">Vila Velha</a>
                                        <a href="https://vitoria.ifes.edu.br/">Vitória</a> 
                                    </LinkList>
                                </Col>
                            </Row>
                        </ScrollAnimation>
                    </Col>
                </MyContainer>
            </Row>
        </>
    )
}