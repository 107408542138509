import React from "react";
import { HeaderParallax, StyledCard } from "./styles";
import { Text } from "./text/indes";

export const Header = (props) => {
  const { page } = props;

  return (
    <>
      <HeaderParallax>
        <h2>Plataforma</h2>
        <h1>Parcerias Ifes</h1>
      </HeaderParallax>
      <StyledCard>
        <Text page={page} />
      </StyledCard>
    </>
  );
};
