import React from "react";
import { Header } from "../../components/header";
import { BigTitle, MyContainer, Title, Text, StyledCard, StyledIcon, FlexBox } from "../../styles";
import { StyledText } from "./styles";
import ScrollAnimation from 'react-animate-on-scroll';
import constants from "../../static";

export const Partnership = () => {
    return (
        <>
        <Header page='partnership'/>
        <MyContainer>
            <BigTitle>
                Como ocorre a parceria entre o Ifes?
            </BigTitle>
            <StyledText>Empresas, governos e o terceiro setor, podem ser parceiros do Ifes, atuando em diferentes formas com o Instituto. Essa colaborações tem como objetivo principal:</StyledText>
        </MyContainer>

        <MyContainer>
            <FlexBox>
                    <StyledCard>
                        <StyledIcon className="fa fa-university" aria-hidden="true"></StyledIcon>
                        <Title>Ensino e extensão</Title>
                        <Text style={{textAlign:'center'}}>Transferir conhecimento e tecnologia do Instituto para a sociedade. </Text>
                    </StyledCard>

                    <StyledCard>
                        <StyledIcon className="fa fa-lightbulb-o" aria-hidden="true"></StyledIcon>
                        <Title>Resolução de problemas sociais</Title>
                        <Text style={{textAlign:'center'}}>Trazer problemas relevantes da sociedade para dentro do contexto acadêmico, como forma de contribuir mais efetiva para o desenvolvimento do país e para formação de recursos humanos no ensino. </Text>
                    </StyledCard>

                    <StyledCard>
                        <StyledIcon className="fa fa-graduation-cap" aria-hidden="true"></StyledIcon>
                        <Title>Investir na formação</Title>
                        <Text style={{textAlign:'center'}}>Permitir a captação de recursos para investimentos na formação de alunos dos níveis técnico, graduação e pós-graduação. </Text>
                    </StyledCard>
            </FlexBox>
        </MyContainer>

        <BigTitle>Como estabelecer parcerias com Ifes?</BigTitle>

        <MyContainer>
            <FlexBox center>
                <ScrollAnimation animateOnce={true} animateIn="bounceInLeft">
                    <a href={constants.sistema}>
                        <StyledCard pink className="zoomEffect">
                            <StyledIcon className="fa fa-pencil-square-o" aria-hidden="true"></StyledIcon>
                            <Title>Mande sua Proposta</Title>
                            <Text style={{textAlign:'center'}}>Preencha o formulário para enviar sua proposta de Parceria </Text>
                        </StyledCard>
                    </a>
                </ScrollAnimation>

                <ScrollAnimation animateOnce={true} animateIn="bounceInRight">
                    <a href={constants.proex}>
                        <StyledCard pink className="zoomEffect">
                            <StyledIcon className="fa fa-sticky-note-o" aria-hidden="true"></StyledIcon>
                            <Title>Termos de Parceria</Title>
                            <Text style={{textAlign:'center'}}>Conheça os Termos de Parceria com o Ifes </Text>
                        </StyledCard>
                    </a>
                </ScrollAnimation>
            </FlexBox>

        </MyContainer>
        
        </>
    )
}