import { Navbar } from 'react-materialize';
import styled from "styled-components";

export const StyledNavbar = styled(Navbar)`
    min-height: 85px;
    background-color: var( --e-global-color-text );
    line-height: 85px;
    padding: 0 50px;

    a {
        font-size: 16px;
        font-weight: 500;
        padding: 0 15px;
        color: #fff; 
    }

    .noStyle{
        padding: 0;
        border: none;
    }

    .dropdown-content {
        background-color: var( --e-global-color-text );
        top:-85px;
    }

    .dropdown-content li>a, .dropdown-content li>span{
        color: #fff;
    }

    .dropdown-content li:hover, .dropdown-content li.active{
        background-color: var(--e-global-color-3);
    }

    img{
        padding: 10px 0;
    }

    @media (max-width: 992px){
        padding: 0 0;
   }
`;