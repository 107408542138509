import { CardPanel } from 'react-materialize';
import styled from 'styled-components';
const HeaderImg = require('../../assets/header.png');

export const HeaderParallax = styled.div`
  background-image: url(${HeaderImg});

  min-height: 360px;
  min-width: 90vw;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  text-align: center;

  h1{
    font-size: 75px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 2px rgb(10 45 17 / 60%);
  }
  h2{
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 1.5px;
    text-shadow: 2px 2px 2px rgb(10 45 17 / 60%);
  }

`;

export const StyledCard = styled(CardPanel)`
    position: relative;
    bottom: 2em;
    box-shadow: 4px 4px 5px 0px rgb(0 0 0 / 50%);
    background-color: var(--e-global-color-text) !important;
    margin: auto auto;
    max-width: 1045px;
    text-align: center;
    font-size: 16px;
    color: #fff;
`;