import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { Footer } from "./components/footer";
import { PartnersNavbar } from "./components/navbar";
import { Home } from "./pages/home";
import { GlobalStyles } from "./styles";
import {MeetIfes} from './components/meetIfes/index'
import ScrollAnimation from 'react-animate-on-scroll';
import { Partnership } from "./pages/partnership";
import { ResearchExtension } from "./pages/researchExtension";
import { Research } from "./pages/research";
import { Extension } from "./pages/extension";
import { Contact } from "./pages/contact";
import { Manuais } from "./pages/manuais";

function App() {
  return (
    <>
   <GlobalStyles/>

   <BrowserRouter>
      <PartnersNavbar/>
      
      <Routes>
        <Route path="/" element={<Home/>}/>

        <Route path="/seja-nosso-parceiro" element={<Partnership/>}/>

        <Route path="/acao-de-pesquisa-e-extensao" element={<ResearchExtension/>}/>

        <Route path="/acoes-da-prppg" element={<Research/>}/>

        <Route path="/acoes-de-extensao" element={<Extension/>}/>

        <Route path="/contato" element={<Contact/>}/>

        <Route path="/manuais" element={<Manuais/>}/>
		
        <Route path="/*" element={<h1>Página Não Encontrada</h1>}/>
      </Routes>

      <ScrollAnimation animateOnce={true} animateIn="fadeIn" >
        <MeetIfes/>
      </ScrollAnimation>
      
      <ScrollAnimation animateOnce={true} animateIn="fadeIn" >
        <Footer/>
      </ScrollAnimation>
   </BrowserRouter>
    </>
  );
}

export default App;
