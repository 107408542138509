import styled from "styled-components";

export const StyledH2 = styled.h2`
    font-size: 28px;
    font-weight: 600;
`;

export const FlashyText = styled.div`
    color: var( --e-global-color-text );
    font-size: 35px;
    font-weight: 700;

    margin: 0 20px;
`;

export const BigText = styled.span`
    font-size: 100px;
    font-weight: bold;
    color: var( --e-global-color-text );
    line-height: 1em;
`;

export const CenterCounter = styled.div`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 270px;
`;

export const LinkList = styled.div`
    display: flex;
    flex-direction: column;
    a{
        margin-bottom: 10px;
    }
`;