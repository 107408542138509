import React from "react";
import { Link } from "react-router-dom";
import { Header } from "../../components/header";
import { BigTitle, MyContainer, StyledCard, StyledIcon, Title, Text, FlexBox } from "../../styles";

export const ResearchExtension = () => {
    return(
        <>
        <Header page='researchExtension'/>
        <BigTitle>Conheça as Ações de Pesquisa e Extensão</BigTitle>
        <MyContainer>
            <FlexBox center>
                <Link to='/acoes-da-prppg'>
                     <StyledCard className="bg-4 zoomEffect content-box">
                        <StyledIcon className="fa fa-search"/>
                        <Title>Ações de Pesquisa</Title>
                        <Text>Conheça as Ações de Pesquisa do Ifes </Text>
                    </StyledCard>
                </Link>

                <Link to='/acoes-de-extensao'>
                    <StyledCard className="bg-4 zoomEffect content-box">
                        <StyledIcon className="fa fa-handshake-o"/>
                        <Title>Ações de Extensão</Title>
                        <Text>Conheça as Ações de Extensão do Ifes </Text>
                    </StyledCard>
                </Link>
            </FlexBox>
        </MyContainer>
        </>
    )
}