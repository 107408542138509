import styled from 'styled-components'
import { Text } from '../../styles'

export const StyledText = styled(Text)`
    max-width: 955px;
    text-align: justify;
    margin: auto auto 30px;
    font-size: 16px;
    font-weight: 500;
    color:#284033D1;
`;