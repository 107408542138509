import styled from 'styled-components'

export const FirstSection = styled.div`
    padding: 30px 30px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: var(--e-global-color-2);
`;

export const SecondSection = styled.div`
    padding: 30px 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;

    background-color: var(--e-global-color-3);
`;

export const SocialMedia = styled.a`
    width: 40px;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    border-radius: 50%;

    margin: 5px;

    :hover {
        opacity: 0.7;
    }

    background: var(--e-global-color-secondary);
    color: white;
`;